##### Version 1.0:

#### Informed Consent (Farm Owner )

Welcome to Grow !

#### What is Grow?

Grow is based out of community project to help farmers enable their farm related resources to be fully utilized. 
Using Grow you can enlist your farm products on truewellth.market

**If you have any concerns or complaints about your rights as a farmer owner and service use **

Contact the SustainHawaii team at kevin@sustainhawaii.org.

**Consent**

By pressing Agree below, it indicates that you have downloaded a copy of this consent form for your own records and that you consent to participate in this product listing service on truewellth.market.

UBC Study ethics ID: H19-01482
