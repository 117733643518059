export const areaProperties = [
  'total_area',
  'total_area_unit',
  'grid_points',
  'perimeter',
  'perimeter_unit',
  'figure_id',
];
export const locationProperties = [
  'farm_id',
  'name',
  'notes',
  'location_id',
  'deleted',
  'location_defaults',
];
export const lineProperties = [
  'length',
  'width',
  'line_points',
  'length_unit',
  'width_unit',
  'figure_id',
  'total_area',
  'total_area_unit',
];
export const pointProperties = ['point', 'figure_id'];
export const figureProperties = ['figure_id', 'type', 'location_id'];

export const barnEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
  grid_points: 'grid_points',
  perimeter: 'perimeter',
  perimeter_unit: 'perimeter_unit',
  wash_and_pack: 'wash_and_pack',
  cold_storage: 'cold_storage',
  used_for_animals: 'used_for_animals',
};
export const watercourseEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  length: 'length',
  width: 'width',
  line_points: 'line_points',
  length_unit: 'length_unit',
  width_unit: 'width_unit',
  used_for_irrigation: 'used_for_irrigation',
  buffer_width: 'buffer_width',
  buffer_width_unit: 'buffer_width_unit',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
};
export const bufferZoneEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  length: 'length',
  width: 'width',
  line_points: 'line_points',
  length_unit: 'length_unit',
  width_unit: 'width_unit',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
};
export const ceremonialEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
  grid_points: 'grid_points',
  perimeter: 'perimeter',
  perimeter_unit: 'perimeter_unit',
};
export const farmSiteBoundaryEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
  grid_points: 'grid_points',
  perimeter: 'perimeter',
  perimeter_unit: 'perimeter_unit',
};
export const fenceEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  length: 'length',
  width: 'width',
  line_points: 'line_points',
  length_unit: 'length_unit',
  width_unit: 'width_unit',
  pressure_treated: 'pressure_treated',
};
export const fieldEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
  grid_points: 'grid_points',
  perimeter: 'perimeter',
  perimeter_unit: 'perimeter_unit',
  station_id: 'station_id',
  organic_status: 'organic_status',
  transition_date: 'transition_date',
};

export const gardenEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
  grid_points: 'grid_points',
  perimeter: 'perimeter',
  perimeter_unit: 'perimeter_unit',
  station_id: 'station_id',
  organic_status: 'organic_status',
  transition_date: 'transition_date',
};
export const gateEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  point: 'point',
};
export const greenhouseEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
  grid_points: 'grid_points',
  perimeter: 'perimeter',
  perimeter_unit: 'perimeter_unit',
  organic_status: 'organic_status',
  transition_date: 'transition_date',
  supplemental_lighting: 'supplemental_lighting',
  co2_enrichment: 'co2_enrichment',
  greenhouse_heated: 'greenhouse_heated',
};
export const naturalAreaEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
  grid_points: 'grid_points',
  perimeter: 'perimeter',
  perimeter_unit: 'perimeter_unit',
};

export const residenceEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
  grid_points: 'grid_points',
  perimeter: 'perimeter',
  perimeter_unit: 'perimeter_unit',
};
export const surfaceWaterEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  total_area: 'total_area',
  total_area_unit: 'total_area_unit',
  grid_points: 'grid_points',
  perimeter: 'perimeter',
  perimeter_unit: 'perimeter_unit',
  used_for_irrigation: 'used_for_irrigation',
};
export const waterValveEnum = {
  farm_id: 'farm_id',
  name: 'name',
  figure_id: 'figure_id',
  type: 'type',
  location_id: 'location_id',
  notes: 'notes',
  point: 'point',
  source: 'source',
  flow_rate: 'flow_rate',
  flow_rate_unit: 'flow_rate_unit',
};

export const userFarmEnum = {
  user_id: 'user_id',
  farm_id: 'farm_id',
  role_id: 'role_id',
  has_consent: 'has_consent',
  status: 'status',
  consent_version: 'consent_version',
  wage: 'wage',
  step_one: 'step_one',
  step_one_end: 'step_one_end',
  step_two: 'step_two',
  step_two_end: 'step_two_end',
  step_three: 'step_three',
  step_three_end: 'step_three_end',
  step_four: 'step_four',
  step_four_end: 'step_four_end',
  step_five: 'step_five',
  step_five_end: 'step_five_end',
  role: 'role',
  first_name: 'first_name',
  last_name: 'last_name',
  profile_picture: 'profile_picture',
  email: 'email',
  phone_number: 'phone_number',
  user_address: 'user_address',
  notification_setting: 'notification_setting',
  language_preference: 'language_preference',
  status_id: 'status_id',
  gender: 'gender',
  birth_year: 'birth_year',
  farm_name: 'farm_name',
  address: 'address',
  units: 'units',
  grid_points: 'grid_points',
  farm_phone_number: 'farm_phone_number',
  owner_name: 'owner_name',
};
